import gql from 'graphql-tag';

export const getLabPageData = gql`
  query {
    getProvinces {
      id
      provinceName
    }
    getSampleTests {
      id
      officialName
      alternateNames
      company
      shortName
      province
    }
  }
`;

export const getSubmissionAndDataByIdList = gql`
  query ($ids: [ID]!) {
    getSubmissionAndDataByLocationIdList(ids: $ids) {
      submission {
        id
        smaUserId
        sampleLocationId
        clientId
        projectId
        siteId
        updatedAt
        createdAt
      }
      user {
        id
        email
        consultantId
      }
      sampleLocation {
        id
        sampleLocName
        sampleLocDatetime
        drillingName
        drillingMethod
        weatherDescription
        windDirection
        windSpeed
        precipitation
        temperature
        latitude
        longitude
        logger
        boreholeComment
        workContextComment
      }
      client {
        id
        name
        comments
      }
      project {
        id
        name
      }
      site {
        id
        siteStatus
        provinceId
        siteStatus
        provinceId
        uwi
        name
        wellLicense
        comments
        latitude
        manual
        longitude
      }
      intervals {
        id
        smaSubmissionId
        intervalTop
        intervalBottom
        plasticity
        moisture
        color
        color1
        color2
        color3
        consistence
        intervalComment
        classSchema
        fragmentShape
        fragmentModifier
        fragmentFragment
        fragmentAdjective
        fragmentTexture
        soilHorizon
        mottleAbundance
        mottleSize
        mottleColor
        mottleContrast
        mottleType
        mottleSelect
        deposition
        primaryConstituent
        primaryProportion
        secondaryConstituent
        secondaryProportion
        tertiaryConstituent
        tertiaryProportion
        grading
        angularity
        odour
        primarySoil
        observation
        geneticTerms
        texture
        structure
        soilFractures
        soilFragments
        soilFragmentsModifier
        soilDisturbance
        mottleDescription
      }
      mottles {
        id
        intervalId
        abundance
        size
        color
        contrast
        type
      }
      atComments {
        id
        sampleLocationId
        depth
        comment
      }
      fieldSamples {
        id
        sampleLocationId
        sampleId
        depth_top
        depth_bottom
        comment
        ec
        ova
        bag
        jar
        vial
        lab
      }
      apec {
        id
        name
        manual
      }
    }
  }
`;

export const createCocLab = gql`
  mutation createQuery(
    $smaSamples: [ID],
    $smaSampleTests: [ID],
    $invoiceName: String!,
    $reportName: String!,
    $additionalReportName: String!,
    $invoiceAddress: String!,
    $reportAddress: String!,
    $additionalReportAddress: String!,
    $invoicePhone: String!,
    $reportPhone: String!,
    $additionalReportPhone: String!,
    $invoiceEmail: String!,
    $reportEmail: String!,
    $additionalReportEmail: String!,
  ) {
    createCocLab(
      cocLab: {
        smaSamples: $smaSamples,
        smaSampleTests: $smaSampleTests,
        invoiceName: $invoiceName,
        reportName: $reportName,
        additionalReportName: $additionalReportName,
        invoiceAddress: $invoiceAddress,
        reportAddress: $reportAddress,
        additionalReportAddress: $additionalReportAddress,
        invoicePhone: $invoicePhone,
        reportPhone: $reportPhone,
        additionalReportPhone: $additionalReportPhone,
        invoiceEmail: $invoiceEmail,
        reportEmail: $reportEmail,
        additionalReportEmail: $additionalReportEmail,
      }
    )
  }
`;
