import React from 'react';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import useWebService from '../../hooks/useWebService';
import updateTests from './queries';

function SampleTestsTabContent() {
  const { getGqlClient } = useWebService();
  const webservice = getGqlClient();
  let responseText;

  const onUpdateTests = () => {
    const response = webservice.query(
      updateTests,
      {},
      { fetchPolicy: 'no-cache' },
    );
    responseText = response;
  };

  return (
    <div className="SampleTestsTabContent">
      <span>
        {responseText}
      </span>
      <IconButton
        onClick={onUpdateTests}
      >
        Update Tests
        <DownloadIcon />
      </IconButton>
    </div>
  );
}

export default SampleTestsTabContent;
