/* eslint-disable no-param-reassign */
/**
 * A dashboard for submitting offline data.
 * Shows all the different things the user can submit, allows them to try to submit.
 */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Grid,
} from '@mui/material';
import {
  Button,
  Alert,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';
import LocalDbService from '../../logic/LocalDbService';
import SubmissionService from '../../logic/SubmissionService';
import LoadingSpinner from '../common/LoadingSpinner';
import BoreholeList from './site-review/BoreholeList';
import PreviousSubmissions from '../PreviousSubmissions/PreviousSubmissions';
import ConvertFromSubmitObjToState from '../../logic/ConvertFromSubmitObjToState';
import { GroupingUtility } from '../common/GroupingUtility';
import CoCPage from './lab-coc/CoCPage';
import Navigation from '../Navigation';

import FilterControlsTypes from '../SubmissionFilterControls/FilterControlsTypes';
import useDbCacheProvider from '../../hooks/useDbCacheProvider';
import useWebService from '../../hooks/useWebService';
import useActiveTab from '../../hooks/useActiveTabProvider';
import useSiteForReview from '../../hooks/useSiteForReviewProvider';
import useEditFormProvider from '../../hooks/useEditFormProvider';
import useReviewSiteProvider from '../../hooks/useReviewSiteProvider';
import useFormProvider from '../../hooks/useFormProvider';

import {
  getTestPoliciesAndRole,
  getPageSubmissionSites,
  getSubmissionAndData,
} from './queries';
import UnsubmittedItems from './UnsubmittedItems/UnsubmittedItems';
import './SubmissionPage.css';

function SubmissionPage() {
  const navigate = useNavigate();
  const { getDbCache } = useDbCacheProvider();
  const [sortedItems, setSortedItems] = useState([]);
  const [errString, setErrString] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [bulkShow, setBulkShow] = useState(false);
  const [reviewBoreholeList, setReviewBoreholeList] = useState([]);
  const [toggleLabCoC, setToggleLabCoC] = useState(false);
  const boreholeCutOff = 30;

  const [previousSubmissions, setPreviousSubmissions] = useState(null);
  const [itemCount, setItemCount] = useState(0);
  const [sites, setSites] = useState(null);
  // eslint-disable-next-line no-undef
  const [sortedBy, setSortedBy] = useState(localStorage
    .getItem('previousSubmissionsSortedBy') || FilterControlsTypes.ALPHABETICAL);
  const [pageSize] = useState(5);
  // eslint-disable-next-line no-undef
  const [current, setCurrent] = useState(parseInt(localStorage.getItem('previousSubmissionsCurrentPage'), 10) || 0);
  // eslint-disable-next-line no-undef
  const [sortedDirection, setSortedDirection] = useState(localStorage
    .getItem('previousSubmissionsSortedDirection') || FilterControlsTypes.DIRECTION_DOWN);
  // eslint-disable-next-line no-undef
  const [searchValue, setSearchValue] = useState(localStorage.getItem('previousSubmissionsActiveSearchValue') || '');
  // eslint-disable-next-line no-undef
  const [activeSearchValue, setActiveSearchValue] = useState(localStorage.getItem('previousSubmissionsActiveSearchValue') || '');

  // eslint-disable-next-line no-unused-vars
  const [testPolicies, setTestPolicies] = useState();
  const [userRole, setUserRole] = useState();
  const readOnly = userRole === 3;
  const [reviewSite] = useState(false);
  const [topIndex, setTopIndex] = useState();

  const { setActiveTab } = useActiveTab();
  const { setSiteForReview, clearSiteForReview, getSiteForReview } = useSiteForReview();
  const siteForReview = getSiteForReview();
  const { toggleReview } = useReviewSiteProvider();
  const { toggleEdit, getIsInFormEdit } = useEditFormProvider();
  const { initialize, duplicate } = useFormProvider();
  const editFormActive = getIsInFormEdit();

  const { mutate, getGqlClient } = useWebService();
  const gqlClient = getGqlClient();

  const fetchPrevSubsAndUpdateState = async (
    newSearchValue,
    newpage = current,
    newSortedBy = sortedBy,
    newSortedDirection = sortedDirection,
  ) => {
    // eslint-disable-next-line no-undef
    if (navigator.onLine) {
      const sitesLocal = await gqlClient.query(
        getPageSubmissionSites,
        {
          sortBy: newSortedBy,
          sortDirection: newSortedDirection,
          searchValue: newSearchValue,
          currentPage: newpage,
          pageSize,
          isAdmin: false,
        },
        { fetchPolicy: 'no-cache' },
      );
      const previousSubmissionsLocal = await gqlClient.query(
        getSubmissionAndData,
        {
          siteIds: sitesLocal.data ? sitesLocal.data.getSubmissionSites.map((sub) => sub.id) : [],
          isAdmin: false,
        },
        { fetchPolicy: 'no-cache' },
      );
      const prevSub = previousSubmissionsLocal?.data?.getSubmissionAndData;
      setPreviousSubmissions(prevSub || []);
      setSites(sitesLocal.data ? sitesLocal.data.getSubmissionSites : []);
      setItemCount(sitesLocal.data ? sitesLocal.data.getSubmissionAndDataItemCount : 0);
    }
  };

  const groupAndSort = (submissionList) => {
    // group everything by the siteName
    const temp = GroupingUtility.groupBy(submissionList, (item) => item.doc.siteName);
    // turn into array of objects
    const objectArray = Object.entries(temp);

    // sort on date && get the last submitted one
    const topInGroup = [];
    objectArray.forEach((item) => {
      // sort by date and get the one that is last submitted.
      item[1]?.sort((a, b) => new Date(
        b.doc.sample_loc_datetime,
      ) - new Date(a.doc.sample_loc_datetime));
      topInGroup.push(new Date(item[1][0].doc.sample_loc_datetime));
      // freeman wants it to be alphabetical so make it alphabetical.
      item[1]?.sort((a, b) => a.doc.borehole.localeCompare(b.doc.borehole));
    });

    const indexOfMaxValue = topInGroup.reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0);
    setSortedItems(objectArray);
    setTopIndex(indexOfMaxValue);

    if (siteForReview.id !== '' && objectArray.length > 0) {
      const siteReviewList = objectArray[siteForReview.id][1]?.map((item) => item.doc);
      setReviewBoreholeList(siteReviewList);
    }
  };

  const updateSubmissionList = () => LocalDbService.list()
    .then((result) => {
      groupAndSort(result.rows);
    });

  const deleteEntity = (event, id = deleteId, update = true) => LocalDbService.delete(id)
    .then(() => {
      setShow(!show);
      setDeleteId('');
      if (update) updateSubmissionList();
    });

  const handleShow = (id) => {
    setShow(!show);
    setDeleteId(id);
  };

  const handleBulkShow = (id) => {
    setBulkShow(!bulkShow);
    setDeleteId(id);
  };

  const handleGroupDelete = () => {
    sortedItems[deleteId][1].forEach((item) => {
      // add this deleted key so it gets deleted
      // eslint-disable-next-line no-underscore-dangle, no-param-reassign
      item.doc._deleted = true;
    });
    const deleteCollection = sortedItems[deleteId][1]?.map((item) => item.doc);

    return LocalDbService.bulk(deleteCollection).then(() => {
      setBulkShow(!bulkShow);
      setDeleteId('');
      updateSubmissionList();
    }).catch(() => {
      setBulkShow(!bulkShow);
      setDeleteId('');
    });
  };

  const SubmittedCOCCLicked = (site) => {
    setToggleLabCoC(!toggleLabCoC);

    const subsForSite = previousSubmissions.filter((sub) => sub.site.id === site.id);
    const converted = subsForSite.map((subForSite) => ConvertFromSubmitObjToState(
      subForSite,
      false,
    ));
    setReviewBoreholeList(converted);

    setSiteForReview({ id: site.id, name: site.name });
  };

  const handleLabCoC = (id) => {
    // Access reviewBorehole list in sortedItems object for
    // specific id using hard-coded index value of 1,
    // since index 0 only contains the name of the site
    const createdReviewBoreholeList = sortedItems[id][1]?.map((item) => item.doc);
    setToggleLabCoC(!toggleLabCoC);
    setReviewBoreholeList(createdReviewBoreholeList);
    setSiteForReview({ id, name: sortedItems[id][0] });
  };

  const handleSiteReview = (id) => {
    const siteReviewList = sortedItems[id][1]?.map((item) => item.doc);
    setReviewBoreholeList(siteReviewList);
    toggleReview();
    setSiteForReview({ id, name: sortedItems[id][0] });
  };

  const deleteOldEntries = () => {
    const today = new Date();
    const cutOffDate = new Date(new Date().setDate(today.getDate() - boreholeCutOff));
    sortedItems.forEach((item) => {
      if (item.length !== 2) return;
      item[1].forEach((borehole) => {
        if (borehole?.doc?.submitted) {
          const submittedDate = Date.parse(borehole?.doc?.submitted);
          if (submittedDate < cutOffDate) deleteEntity({}, borehole.id, false);
        }
      });
    });
  };

  const fetchTestPoliciesAndUpdateState = async () => {
    if (userRole) {
      const testPoliciesAndRole = await gqlClient.query(
        getTestPoliciesAndRole,
        {},
        { fetchPolicy: 'no-cache' },
      );

      setTestPolicies(testPoliciesAndRole?.data?.getTestPolicies || []);
      setUserRole(testPoliciesAndRole?.data?.getSmaUserRole);
    }
  };

  const submissionErrorMessage = (error) => {
    // eslint-disable-next-line no-undef
    if (!navigator.onLine) {
      return 'Error submitting borehole log. Please ensure you are connected to the internet.';
    }
    return `Error submitting borehole log. There was an issue with the borehole: ${error}`;
  };

  const submitEntity = (item) => {
    setLoading(true);
    SubmissionService(item.id, getDbCache(), mutate, gqlClient).then((locationId) => {
      item.doc.submitted = new Date().toISOString();
      item.doc.id = locationId;
      LocalDbService.saveWithRev(item.doc).then(() => {
        fetchPrevSubsAndUpdateState(activeSearchValue);
        updateSubmissionList();
        setLoading(false);
        setErrString('');
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setLoading(false);
        setErrString('Error saving locally. Do not retry submitting this report.');
      });
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      setLoading(false);
      setErrString(submissionErrorMessage(err.toString()));
    });
  };

  const closeReviewView = () => {
    toggleReview();
    clearSiteForReview();
  };

  const editSubmittedEntity = (submission, activeTab) => {
    if (editFormActive) {
      setErrString('Currently editing a form. Please go back to Entry Form Page and finish your edits or cancel.');
      return;
    }
    const editObj = {
      ...ConvertFromSubmitObjToState(submission, false),
      isSubmissionEdit: true,
      submitted: undefined,
      id: submission.submission.id,
      _id: `${submission.submission.name}__${new Date().toISOString()}`, // needed for pouchdb
    };
    initialize(editObj);
    toggleEdit(true);
    setActiveTab(activeTab);
    navigate('/field');
  };

  const editEntity = (id, activeTab) => {
    if (editFormActive) {
      setErrString('Currently editing a form. Please go back to Entry Form Page and finish your edits or cancel.');
    } else {
      setLoading(true);
      LocalDbService.get(id)
        .then((results) => {
          setLoading(false);
          initialize({
            ...results,
            submitted: undefined,
          });
          toggleEdit(true);
          setActiveTab(activeTab);
          navigate('/field');
        })
        .catch((err) => {
          setLoading(false);
          setErrString(err.toString());
        });
    }
  };

  /**
  * Function generates an object that can be used for duplication if the data comes
  * from previous submissions.
  * @param {*} submission A submission object used for the conversion.
  */
  const duplicateSubmittedObj = (subPage, submission) => {
    const dupObj = ConvertFromSubmitObjToState(submission, true);

    toggleEdit(false);
    duplicate(dupObj);

    setActiveTab(2);
    navigate('/field');
  };

  const duplicateEntity = (id) => {
    setLoading(true);
    LocalDbService.get(id)
      .then((results) => {
        duplicate(results);
        setActiveTab(2);
        navigate('/field');
      }).catch((err) => {
        setLoading(false);
        setErrString(err.toString());
      });
  };

  const reviewEditEntity = (id, tab) => {
    editEntity(id, tab);
  };

  useEffect(() => {
    const builtLocalDbService = LocalDbService;
    builtLocalDbService.init();
    builtLocalDbService.list().then((response) => {
      groupAndSort(response.rows);
    });
    // eslint-disable-next-line no-undef
    if (navigator.onLine) {
      fetchTestPoliciesAndUpdateState();
    }

    updateSubmissionList().then(() => {
      deleteOldEntries();
    });
  }, []);

  if (toggleLabCoC) {
    return (
      <CoCPage
        boreholes={reviewBoreholeList}
        siteName={siteForReview.name}
        initialize={initialize}
        toggleLabCoC={() => setToggleLabCoC(!toggleLabCoC)}
        updateSubmissionList={updateSubmissionList}
      />
    );
  }

  if (reviewSite) {
    return (
      <div>
        <Grid>
          <Row>
            <Col xs={6} span={6}>
              <Button block onClick={() => closeReviewView()}>Go Back to All Borehole Logs</Button>
            </Col>
          </Row>
          <h3>{siteForReview.name}</h3>
          {errString && <Alert bsStyle="danger">{errString}</Alert> }
          <BoreholeList list={reviewBoreholeList} editBorehole={reviewEditEntity} />
        </Grid>
      </div>
    );
  }

  return (
    <div className="SubmissionPage">
      <Navigation />
      <Modal show={show} onHide={() => handleShow('')}>
        <Modal.Body>Are you sure you want to delete this borehole log?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleShow('')}>Close</Button>
          <Button bsStyle="primary" onClick={deleteEntity}>Delete Entry</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={bulkShow} onHide={() => handleBulkShow('')}>
        <Modal.Body>
          <p>Are you sure you want to delete this collection of borehole logs?</p>
          <p style={{ color: 'red' }}>
            {'THIS IS AN IRREVERSIBLE ACTION.'
            + "ALL COLLECTED DATA WILL BE REMOVED UNLESS IT'S BEEN SUBMITTED."}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleBulkShow('')}>Close</Button>
          <Button bsStyle="primary" onClick={handleGroupDelete}>Delete Collection</Button>
        </Modal.Footer>
      </Modal>
      <div className="SubmissionPageContent">
        <Grid>
          {loading && <LoadingSpinner centered />}
          {!readOnly && (
            <Row>
              <Col xs={6} span={6}>
                <LinkContainer to="/field">
                  <Button className="FieldFormButton">Field Form</Button>
                </LinkContainer>
              </Col>
            </Row>
          )}
          {!readOnly && (
            <>
              <h3 className="boreholeLogs">Borehole Logs</h3>
              <UnsubmittedItems
                loading={loading}
                sortedItems={sortedItems}
                errString={errString}
                userRole={userRole}
                topIndex={topIndex}
                duplicateEntity={duplicateEntity}
                editEntity={editEntity}
                handleShow={handleShow}
                submitEntity={submitEntity}
                handleLabCoC={handleLabCoC}
                handleSiteReview={handleSiteReview}
                handleBulkShow={handleBulkShow}
              />
            </>
          )}
          {!loading && (
            <PreviousSubmissions
              duplicateEntity={duplicateSubmittedObj}
              editEntity={editSubmittedEntity}
              readOnly={readOnly}
              previousSubmissions={previousSubmissions}
              setPreviousSubmissions={setPreviousSubmissions}
              fetchPrevSubsAndUpdateState={fetchPrevSubsAndUpdateState}
              setSortedDirection={setSortedDirection}
              setCurrent={setCurrent}
              setActiveSearchValue={setActiveSearchValue}
              activeSearchValue={activeSearchValue}
              setSearchValue={setSearchValue}
              setSortedBy={setSortedBy}
              searchValue={searchValue}
              sites={sites}
              itemCount={itemCount}
              pageSize={pageSize}
              sortedBy={sortedBy}
              sortedDirection={sortedDirection}
              current={current}
              SubmittedCOCCLicked={SubmittedCOCCLicked}
            />
          )}
        </Grid>
      </div>
    </div>
  );
}

export default SubmissionPage;
