import React, { useState } from 'react';
import PreviousSubmissions from '../PreviousSubmissions/PreviousSubmissions';
import UserAdminForm from '../UserAdminForm/UserAdminForm';
import SiteAdminForm from '../SiteAdminForm';
import FilterControlsTypes from '../SubmissionFilterControls/FilterControlsTypes';
import useWebService from '../../hooks/useWebService';
import SampleTestsTabContent from '../SampleTestsTabContent';
import ProjectAdminForm from '../ProjectAdminForm';
import {
  getPageSubmissionSites,
  getSubmissionAndData,
} from './queries';
import './AdminPage.css';

const Tabs = {
  Sites: 'Sites',
  Users: 'Users',
  PreviousSubmissions: 'PreviousSubmissions',
  Projects: 'Projects',
  SampleTests: 'sampleTests',
};

function AdminPage() {
  const [currentTab, setCurrentTab] = useState(Tabs.PreviousSubmissions);
  const [previousSubmissions, setPreviousSubmissions] = useState(null);
  const [current, setCurrent] = useState(parseInt(localStorage.getItem('previousSubmissionsCurrentPage'), 10) || 0);
  const [sortedBy, setSortedBy] = useState(localStorage
    .getItem('previousSubmissionsSortedBy') || FilterControlsTypes.ALPHABETICAL);
    // eslint-disable-next-line no-undef
  const [sortedDirection, setSortedDirection] = useState(localStorage
    .getItem('previousSubmissionsSortedDirection') || FilterControlsTypes.DIRECTION_DOWN);
  // eslint-disable-next-line no-undef
  const [activeSearchValue, setActiveSearchValue] = useState(
    localStorage.getItem('previousSubmissionsActiveSearchValue')
    || '',
  );
  const [searchValue, setSearchValue] = useState(
    localStorage.getItem('previousSubmissionsActiveSearchValue')
    || '',
  );
  const [itemCount, setItemCount] = useState(0);
  const [sites, setSites] = useState(null);
  const { getGqlClient } = useWebService();
  const [pageSize] = useState(5);
  const gqlClient = getGqlClient();

  const onDuplicateEntity = () => {

  };

  const editSubmittedEntity = () => {

  };

  const SubmittedCOCCLicked = () => {

  };

  const fetchPrevSubsAndUpdateState = async (
    newSearchValue,
    newpage = current,
    newSortedBy = sortedBy,
    newSortedDirection = sortedDirection,
  ) => {
    // eslint-disable-next-line no-undef
    if (navigator.onLine) {
      const sitesLocal = await gqlClient.query(
        getPageSubmissionSites,
        {
          sortBy: newSortedBy,
          sortDirection: newSortedDirection,
          searchValue: newSearchValue,
          currentPage: newpage,
          pageSize,
          isAdmin: false,
        },
        { fetchPolicy: 'no-cache' },
      );
      const previousSubmissionsLocal = await gqlClient.query(
        getSubmissionAndData,
        {
          siteIds: sitesLocal.data ? sitesLocal.data.getSubmissionSites.map((sub) => sub.id) : [],
          isAdmin: false,
        },
        { fetchPolicy: 'no-cache' },
      );
      const prevSub = previousSubmissionsLocal?.data?.getSubmissionAndData;
      setPreviousSubmissions(prevSub || []);
      setSites(sitesLocal.data ? sitesLocal.data.getSubmissionSites : []);
      setItemCount(sitesLocal.data ? sitesLocal.data.getSubmissionAndDataItemCount : 0);
    }
  };

  return (
    <div className="AdminPage">
      <span className="AdminPageTitle">ADMIN PAGE</span>
      <div className="TabArea">
        {Object.values(Tabs).map((tab) => (
          <>
            {/* eslint-disable-next-line */ }
            <div
              className={`TabButton ${tab === currentTab ? 'selected' : ''}`}
              onClick={() => setCurrentTab(tab)}
            >
              <span>{tab}</span>
            </div>
          </>
        ))}
      </div>
      <div className="TabContent">
        {currentTab === Tabs.Users && (
          <UserAdminForm />
        )}
        {currentTab === Tabs.Sites && (
          <SiteAdminForm />
        )}
        {currentTab === Tabs.PreviousSubmissions && (
          <div className="Submissions">
            <PreviousSubmissions
              duplicateEntity={onDuplicateEntity}
              editEntity={editSubmittedEntity}
              readOnly
              previousSubmissions={previousSubmissions}
              setPreviousSubmissions={setPreviousSubmissions}
              fetchPrevSubsAndUpdateState={fetchPrevSubsAndUpdateState}
              setSortedDirection={setSortedDirection}
              setCurrent={setCurrent}
              setActiveSearchValue={setActiveSearchValue}
              activeSearchValue={activeSearchValue}
              setSearchValue={setSearchValue}
              setSortedBy={setSortedBy}
              searchValue={searchValue}
              sites={sites}
              itemCount={itemCount}
              pageSize={pageSize}
              sortedBy={sortedBy}
              sortedDirection={sortedDirection}
              current={current}
              SubmittedCOCCLicked={SubmittedCOCCLicked}
            />
          </div>
        )}
        {currentTab === Tabs.Projects && (
          <ProjectAdminForm />
        )}
        {currentTab === Tabs.SampleTests && (
          <SampleTestsTabContent />
        )}
      </div>
    </div>
  );
}

export default AdminPage;
