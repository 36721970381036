/**
 * Review Pane for Field component
 */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row, Col, Button, Alert, Modal,
} from 'react-bootstrap';
import { Grid, Box } from '@mui/material';
import { LinkContainer } from 'react-router-bootstrap';
import Navigation from '../Navigation';
import { serializeFromState } from '../../logic/submittables/SampleLocation';

import useActiveTabProvider from '../../hooks/useActiveTabProvider';

import useEditFormProvider from '../../hooks/useEditFormProvider';
import useFormProvider from '../../hooks/useFormProvider';
import useErrorsProvider from '../../hooks/useErrorsProvider';
import useOfflineServiceProvider from '../../hooks/useOfflineServiceProvider';
import './Review.css';

function Review() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { setActiveTab } = useActiveTabProvider();
  const { toggleEdit, getIsInFormEdit } = useEditFormProvider();
  const { saveOrUpdateSubmittable } = useOfflineServiceProvider();
  const { getForm } = useFormProvider();
  const formState = getForm();
  const editForm = getIsInFormEdit();

  const { clearForm, validateFields, getErrors } = useFormProvider();
  const { setSubmissionError } = useErrorsProvider();
  const errors = getErrors();

  // Helper function to check which validation errors are present in the
  // sampleCollection errors, only 'sample' and 'depth_top' errors should
  // prevent user from saving the form.
  const sampleCollectionHasErrors = (values) => {
    for (let i = 0; i < values.length; i += 1) {
      if (values[i] !== undefined) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key] of Object.entries(values[i])) {
          if (key === 'sample' || key === 'depth_top') {
            return true;
          }
        }
      }
    }
    return false;
  };

  const disableSaveButton = () => {
    // Checking specific errors on the form as not all errors should disable the
    // button, only the required fields in this case some "sampleCollection",
    // "sampleCollection_message", "interval_depth_form", and "interval_description_form"
    // eslint-disable-next-line no-restricted-syntax
    let hasErrors = false;
    Object.keys(errors).forEach((error) => {
      if (error.name === 'sampleCollection') {
        if (sampleCollectionHasErrors(error.value)) {
          hasErrors = true || hasErrors;
        }
      }
      if (error.name !== 'sampleCollection' && error.name !== 'sampleCollectionMessage'
        && error.name !== 'interval_depth_form' && error.name !== 'interval_description_form') {
        hasErrors = true || hasErrors;
      }
      hasErrors = false || hasErrors;
    });
    return hasErrors;
  };

  const formatErrorMessage = () => {
    const errorsLocal = Object.keys(errors).map((key) => {
      const value = errors[key];
      if (value === 'Latitude is required') return 'Latitude';
      if (value === 'Longitude is required') return 'Longitude';
      if (typeof value === 'object') { // handle form sections (depth form and description form)
        return ''; // dont want interval errors in the message list
      }
      return value;
    }).filter((value) => value !== ''); // remove empties

    if (errorsLocal.length > 0) {
      return errorsLocal.join(', ');
    }
    return null; // return nothing so the alert doesnt show up
  };

  const handleShow = () => {
    setShow(!show);
  };

  const submitBorehole = () => {
    const serialized = serializeFromState(formState);
    // eslint-disable-next-line no-unused-vars
    saveOrUpdateSubmittable(serialized, editForm).then((_response) => {
      setActiveTab(1);
      clearForm();
      navigate('/submission');
    }).catch((error) => {
      setSubmissionError(error.message);
      // eslint-disable-next-line no-console
      console.error(error);
    });
    if (editForm) toggleEdit(false);
  };

  useEffect(() => {
    validateFields();
  }, []);

  return (
    <div>
      <Navigation />
      <Modal show={show} onHide={handleShow}>
        <Modal.Body>
          <p>
            <b>Back to Form</b>
            {' '}
            - will take you back to the form so you can make changes
          </p>
          <p>
            <b>Save/Edit</b>
            {' '}
            - will save your changes and take you to the home screen
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Grid className="ReviewPageContent">
        <Row style={{ marginBottom: 10 }}>
          <Col xs={6} span={2}>
            <LinkContainer to={{ pathname: '/field' }}>
              <Button className="backToFormButton">Back to Form</Button>
            </LinkContainer>
          </Col>
          <Col xs={6} span={2}>
            <Button
              bsStyle="success"
              disabled={disableSaveButton()}
              onClick={() => submitBorehole()}
              block
              className="saveFormButton"
            >
              Save
              {editForm && ' Edit'}
              {' '}
              Locally
            </Button>
          </Col>
        </Row>
        <h3>Review</h3>
        <Box>
          <h2>
            Data
          </h2>
          <>
            {Object.keys(errors).length > 0
            && (
              <Alert bsStyle="danger">
                Missing the following:
                {' '}
                {formatErrorMessage()}
              </Alert>
            )}
            <pre>
              {JSON.stringify(formState, (key, value) => (value === null || value === '' ? undefined : value), 2)}
            </pre>
          </>
        </Box>
      </Grid>
    </div>
  );
}

export default Review;
